export type ServiceTier = number;
export const ServiceTier = {
    TIER1: 1,
    TIER2: 2,
    TIER3: 3,
    TIER4: 4,

    1: "TIER1",
    2: "TIER2",
    3: "TIER3",
    4: "TIER4",

    get values() { return Object.keys(this).filter(key => parseInt(key) != key as any && key === key.toUpperCase()) }
}
export const serviceTiers = [
    { id: ServiceTier.TIER1, label: 'Tier 1', icon: 'looks_one' },
    { id: ServiceTier.TIER2, label: 'Tier 2', icon: 'looks_two' },
    { id: ServiceTier.TIER3, label: 'Tier 3', icon: 'looks_3' },
    { id: ServiceTier.TIER4, label: 'Tier 4', icon: 'looks_4' }
];
